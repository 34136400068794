(function(d,t) {
    alert('chatbot')
    var BASE_URL="https://chat.dillan.cloud";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
        window.chatwootSDK.run({
        websiteToken: 'pAsMJhFKUZQoMmTPhFFbJbwH',
        baseUrl: BASE_URL
        })
    }
    })(document,"script");